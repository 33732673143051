const splitString = (text: { name: string }) =>
  text.name.replace('  ', ' ').split(' ')[1] || text.name;
const prepareString = (text: { name: string }, split = false) =>
  (split ? splitString(text) : text.name).toLocaleLowerCase();

const extractNameParts = (fullName: string) => {
  const trimmedName = fullName.trim().replace('  ', ' ');
  const lastSpaceIndex = trimmedName.lastIndexOf(' ');
  if (lastSpaceIndex === -1) return [trimmedName, ''];

  const firstName = trimmedName.slice(0, lastSpaceIndex);
  const lastName = trimmedName.slice(lastSpaceIndex + 1);
  return [firstName, lastName];
};

export const sortByBothNames = (
  a: { name: string },
  b: { name: string },
  reverse = false,
): number => {
  const [aFirstName, aLastName] = extractNameParts(a.name);
  const [bFirstName, bLastName] = extractNameParts(b.name);

  const aLast = aLastName.toLocaleLowerCase();
  const bLast = bLastName.toLocaleLowerCase();

  if (aLast < bLast) return reverse ? 1 : -1;
  if (aLast > bLast) return reverse ? -1 : 1;

  const aFirst = aFirstName.toLocaleLowerCase();
  const bFirst = bFirstName.toLocaleLowerCase();

  if (aFirst < bFirst) return reverse ? 1 : -1;
  if (aFirst > bFirst) return reverse ? -1 : 1;

  return 0;
};

export default (
  a: { name: string },
  b: { name: string },
  split = false,
  reverse = false,
): number => {
  if (reverse) {
    return prepareString(a, split) < prepareString(b, split) ? 1 : -1;
  }
  return prepareString(a, split) < prepareString(b, split) ? -1 : 1;
};
