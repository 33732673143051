/* eslint-disable jsx-a11y/tabindex-no-positive */
import { ReactElement, useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import api from '~/services/api';
import Input from '~/ui/components/inputs/Input';
import SelectEmailDomain from '~/ui/components/inputs/SelectEmailDomain';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import InputMask from '~/ui/components/inputs/InputMask';
import AvatarUpload from '~/ui/components/inputs/AvatarUpload';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Button from '~/ui/components/common/Button';
import NavigationConfirmModal from '~/ui/components/common/NavigationConfirmModal';
import Loader from '~/ui/components/common/Loader';

import createValidationSchema, { editValidationSchema } from './validate';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import { extractErrorMessage } from '~/utils/error/error';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { ILocalAdminForm } from '~/services/api/localAdmin/types';
import { IOption, IUserRole } from '~/types';
import { IActTeamMember } from '~/services/api/actTeamMember/types';
import memberOptions, { MemberType } from '~/ui/constants/memberTypes';

import styles from './Form.module.scss';

interface IProps {
  defaultValues?: ILocalAdminForm;
  isEdit?: boolean;
  submitText?: string;
  isProcessing: boolean;
  leavePageDescription: string;
  cancelLinkTo: any;
  emailDomainOptions?: IOption[];
  isPending?: boolean;
  onSubmit: (vals: ILocalAdminForm) => void;
}

interface IOptions {
  states: IOption[];
  credentials: IOption[];
  educations: IOption[];
  employmentStatuses: IOption[];
  subRoles: IOption[];
}

const Form = ({
  defaultValues,
  isEdit = false,
  submitText = 'Send Invite',
  isProcessing,
  leavePageDescription,
  cancelLinkTo,
  emailDomainOptions,
  isPending,
  onSubmit,
}: IProps): ReactElement => {
  const [options, setOptions] = useState<IOptions>(null);
  const [loading, setLoading] = useState(false);

  const showError = useStoreActions(actions => actions.snackbar.showError);
  const onGetActTeamsAvailable = useStoreActions(actions => actions.actTeam.onGetActTeamsAvailable);

  const availableActTeams = useStoreState(state => state.actTeam.availableActTeams);
  const clinic = useStoreState(state => state.clinic.current);
  const teamMember = useStoreState(state => state.actTeamMember.current);

  const { isLocalAdmin } = useRole();

  const teamOptions = useMemo(
    () => formatDictionaryOptions(availableActTeams),
    [availableActTeams],
  );

  const currentClinicRoles = (teamMember as IActTeamMember)?.roles?.filter(
    role => role.clinic.id === Number(clinic.id),
  );

  const isGlobalUser = !!currentClinicRoles?.find(role => role.name === IUserRole.GlobalUser);
  const currentTeamMember = defaultValues?.primaryRole === MemberType.TEAM_MEMBER;
  const currentTeamLeader = defaultValues?.primaryRole === MemberType.TEAM_LEADER;

  // role can be changed from global user to team member or team leader and from team member or team leader to global user
  // no requirements and api ability to change from team member to team leader and vice versa
  const filteredMemberOptions = useMemo(() => {
    if (currentTeamMember) {
      return memberOptions.filter(o => o.value !== MemberType.TEAM_LEADER);
    }
    if (currentTeamLeader) {
      return memberOptions.filter(o => o.value !== MemberType.TEAM_MEMBER);
    }
    return memberOptions;
  }, [currentTeamLeader, currentTeamMember]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: isEdit ? editValidationSchema : createValidationSchema,
    // shouldUnregister: true,
  });

  const { primaryRole } = watch();
  const globalUserSelected = primaryRole === MemberType.GLOBAL_USER;

  // fetch available team options if global user role selected
  const fetchAvailableTeams = useCallback(async () => {
    if ((isGlobalUser || globalUserSelected) && clinic?.id) {
      try {
        setLoading(true);
        await onGetActTeamsAvailable({ clinicId: String(clinic.id) });
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  }, [isGlobalUser, globalUserSelected, clinic.id, onGetActTeamsAvailable, showError]);

  useEffect(() => {
    fetchAvailableTeams();
  }, [fetchAvailableTeams]);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const [states, credentials, educations, employmentStatuses, subRoles] = await Promise.all([
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.State)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Credentials)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.Education)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.EmploymentStatus)
          .then(r => formatDictionaryOptions(r.data)),
        api.dictionaries
          .getAvailableTypeList(IDictionaryTypes.SubRole)
          .then(r => formatDictionaryOptions(r.data)),
      ]);

      setOptions({ states, credentials, educations, employmentStatuses, subRoles });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [showError]);

  const setAll = useCallback(
    (all?: boolean) => {
      if (all) {
        const filteredTeamIds = availableActTeams.map(m => m.id);
        setValue('teamIds', filteredTeamIds);
      } else {
        setValue('teamIds', []);
      }
    },
    [availableActTeams, setValue],
  );

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (emailDomainOptions?.length && !defaultValues?.emailDomain) {
      setValue('emailDomain', emailDomainOptions[0].value as string);
    }
  }, [defaultValues?.emailDomain, emailDomainOptions, setValue]);

  if (loading || !options) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <Paper>
          <Box sx={{ p: 2, pr: 0 }}>
            <p className={styles.formTitle}>Personal Details</p>
            <Grid container>
              <Grid item sm={6}>
                <Grid container spacing={2} sm={12}>
                  <Grid item sm={12}>
                    <Input
                      tabIndex={1}
                      register={register}
                      errors={errors}
                      name="firstName"
                      label="First Name"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <Input
                      tabIndex={2}
                      register={register}
                      errors={errors}
                      name="lastName"
                      label="Last Name"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <InputMask
                      tabIndex={3}
                      control={control}
                      errors={errors}
                      name="phone"
                      label="Phone"
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <div className={styles.combinedInputWrapper}>
                      <Input
                        combinedInput={!isEdit || isPending}
                        disabled={isEdit && !isPending}
                        register={register}
                        errors={errors}
                        name="email"
                        label="Email"
                      />
                      {(!isEdit || isPending) && (
                        <SelectEmailDomain
                          isDisabled={isEdit}
                          name="emailDomain"
                          control={control}
                          errors={errors}
                          options={emailDomainOptions}
                        />
                      )}
                    </div>
                  </Grid>

                  <Grid item sm={12}>
                    <AvatarUpload control={control} name="photo" errors={errors} isPhoto />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid container spacing={2} sm={12}>
                  <Grid item sm={12}>
                    <Select
                      tabIndex={4}
                      isMulti
                      control={control}
                      errors={errors}
                      name="subRoleIds"
                      label="Sub-Roles"
                      options={options.subRoles}
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  {isLocalAdmin && (
                    <>
                      <Grid item sm={globalUserSelected ? 6 : 12}>
                        <Select
                          tabIndex={5}
                          options={filteredMemberOptions}
                          name="primaryRole"
                          control={control}
                          errors={errors}
                          label="Primary Role"
                          hideSelectedOptions={false}
                          openMenuOnFocus
                        />
                      </Grid>
                      {globalUserSelected && (
                        <Grid item sm={6}>
                          <Select
                            tabIndex={4}
                            isMulti
                            oneLineMulti
                            closeMenuOnSelect={false}
                            showCustomComponents
                            showSelectAll
                            hideSelectedOptions={false}
                            name="teamIds"
                            options={teamOptions}
                            control={control}
                            errors={errors}
                            label="Assigned Teams"
                            isClearable
                            placeholder="All"
                            setAll={setAll}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item sm={12}>
                    <Input
                      tabIndex={5}
                      register={register}
                      errors={errors}
                      name="address.city"
                      label="City"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Input
                      tabIndex={6}
                      register={register}
                      errors={errors}
                      name="address.line1"
                      label="Address 1"
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <Input
                      tabIndex={7}
                      register={register}
                      errors={errors}
                      name="address.line2"
                      label="Address 2"
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Select
                      tabIndex={8}
                      options={options.states}
                      name="address.stateId"
                      control={control}
                      errors={errors}
                      label="State"
                      hideSelectedOptions={false}
                      openMenuOnFocus
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputMask
                      tabIndex={9}
                      alwaysShowMask={false}
                      mask="99999"
                      name="address.zipCode"
                      control={control}
                      errors={errors}
                      label="Zip Code"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <div className={styles.paperContainer}>
          <Paper>
            <Box sx={{ p: 2 }}>
              <p className={styles.formTitle}>Work Details</p>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Select
                    tabIndex={10}
                    options={options.credentials}
                    control={control}
                    errors={errors}
                    name="workDetails.credentialsId"
                    label="Credentials"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={13}
                    options={options.employmentStatuses}
                    control={control}
                    errors={errors}
                    name="workDetails.employmentStatusId"
                    label="Employment Status"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <Select
                    tabIndex={11}
                    options={options.educations}
                    control={control}
                    errors={errors}
                    name="workDetails.educationId"
                    label="Education"
                    hideSelectedOptions={false}
                    openMenuOnFocus
                  />
                </Grid>
                <Grid item sm={6}>
                  <Input
                    tabIndex={14}
                    type="number"
                    register={register}
                    errors={errors}
                    name="workDetails.experience"
                    label="Years of Experience"
                  />
                </Grid>
                <Grid item sm={6}>
                  <DatePicker
                    tabIndex={12}
                    openTo="date"
                    control={control}
                    errors={errors}
                    label="Hire Date"
                    name="workDetails.hireDate"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <div className={styles.buttonsWrapper}>
            <div className={styles.buttonWrapper}>
              <Link to={cancelLinkTo} className={styles.link}>
                <Button variant="outlined" color="primary" disabled={isProcessing}>
                  Cancel
                </Button>
              </Link>
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isProcessing}
              tabIndex={15}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </form>
      <NavigationConfirmModal when={isDirty && !isProcessing} description={leavePageDescription} />
    </div>
  );
};

export default Form;
